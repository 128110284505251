import { useState, useEffect } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { classNames } from '@/utils/classNames';
import PropTypes from 'prop-types';

function Input({
    label,
    placeholder,
    value,
    type,
    name,
    required,
    error,
    onChange,
    onBlur,
    className,
    onKeyUp,
}) {
    // If a value is passed in set the current state to it.
    const [val, setVal] = useState('');

    // Handle typing into the input
    const handleChange = (e) => {
        setVal(e.target.value);
        if (onChange) onChange(e);
    };

    const keyUp = (e) => {
        if (onKeyUp) onKeyUp(e);
    }

    // Handle external value
    useEffect(() => {
        if (value) {
            setVal(value);
        }
    }, [value]);

    return (
        <div className={className}>
            <label
                htmlFor={name}
                className="block font-medium text-black text-small-copy">
                {label}
                {required && <span className="text-terracotta">*</span>}
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
                <input
                    type={type}
                    name={name}
                    value={val}
                    onChange={handleChange}
                    onBlur={onBlur}
                    id={name}
                    className={classNames(
                        error
                            ? 'text-terracotta placeholder-peach border-terracotta focus:ring-terracotta focus:border-terracotta'
                            : 'bg-white placeholder-heather border-granite focus:ring-granite focus:border-granite',
                        'relative block w-full p-3 pr-10 border-2 rounded-lg focus:outline-none  sm:text-sm'
                    )}
                    placeholder={placeholder}
                    onKeyUp={keyUp}
                />
                {error && (
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <ExclamationCircleIcon
                            className="w-5 h-5 text-terracotta"
                            aria-hidden="true"
                        />
                    </div>
                )}
            </div>
            {error && (
                <p
                    className="mt-2 text-small-copy text-terracotta"
                    >
                    {error}
                </p>
            )}
        </div>
    );
}

Input.defaultProps = {
    placeholder: '',
    required: false,
};

Input.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    error: PropTypes.string,
    onKeyUp: PropTypes.func
};

export default Input;
