import { useState, useEffect } from 'react';
import FormInput from '@/components/Forms/FormInput';
import Button from '@/components/Button/Button';
import { httpPostWithCsrfToken } from '@/utils/Http';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { keysToCamelFromSnake } from '@/utils/caseconverters';
import { useRouter } from 'next/router';
import { httpGet } from 'utils/Http';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, user } from '/store/user/userSlice';
import { HomeIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import Footer from '@/components/Footer/Footer';
import LogoBlack from '@/components/LogoBlack/LogoBlack';
// import { serialize } from 'cookie';
var cookie = require('cookie');

function LoginPage() {
    const dispatch = useDispatch();

    const router = useRouter();
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const user = useSelector((state) => state.user);

    const updateCredentials = (input) => {
        const inputVals = { ...credentials };
        inputVals[input.target.name] = input.target.value;
        setCredentials(inputVals);
    };

    useEffect(() => {
    }, [user]);

    const handleSubmit = (e) => {
        setErrors({});
        setSubmitted(true);
        httpPostWithCsrfToken(`${process.env.NEXT_PUBLIC_AUTH_API_URL}/login/`, credentials)
            .then((key) => {
                httpGet(`${process.env.NEXT_PUBLIC_AUTH_API_URL}/user/`, true)
                    .then((data) => {
                        document.cookie = cookie.serialize('user', data.expired, {
                            path: '/',
                        });
                       return dispatch(setUser(keysToCamelFromSnake(data)))
                    })
                    .then((res) => {
                        if (router.query && router.query.next) {
                            router.push(router.query.next);
                        } else {
                            router.push(res?.payload.selectedSection.value || '/account');
                        }
                    });
            })
            .catch((error) => {
                error.response
                    .json()
                    .then((errors) => {
                        setErrors(keysToCamelFromSnake(errors));
                        setSubmitted(false);
                    })
                    .catch((crap) => {
                        setErrors({ nonFieldErrors: [error.message] });
                        setSubmitted(false);
                    });
            });
    };

    const keyUp = (e) => {
        if (e.keyCode == 13) {
            handleSubmit(e);
        }
    };

    const newUser = router.query.newuser;

    return (
        <>
            <div className="flex flex-col items-center min-h-[90vh] p-5">
                <LogoBlack className="w-32 md:w-40 md:mr-auto" />
                <div className="col-span-2 rounded-md bg-peach w-full max-w-[450px] md:w-[450px] mt-8 md:mt-16">
                    <div className="p-8">
                        <h1 className="text-center h1">Login</h1>
                        {newUser && (
                            <div className="relative p-2">
                                <p className="mt-2 text-small-copy">
                                Great job, you're all signed up! <br />Now enter your email and password to access the Kete.
                                </p>
                            </div>
                        )}
                        <div className="pt-8">
                            {errors.nonFieldErrors &&
                                errors.nonFieldErrors.map((error) => {
                                    return (
                                        <div className="relative pb-6" key={error}>
                                            <div className="absolute top-0 right-0 flex items-center pr-3 pointer-events-none">
                                                <ExclamationCircleIcon className="w-5 h-5 text-terracotta" aria-hidden="true" />
                                            </div>
                                            <p className="mt-2 text-small-copy text-terracotta">{error}</p>
                                        </div>
                                    );
                                })}

                            <FormInput
                                label="Email"
                                type="email"
                                name="email"
                                placeholder="email@etutangata.co.nz"
                                value={credentials.email}
                                onChange={updateCredentials}
                                className="pb-6"
                                error={errors.email}
                                onKeyUp={keyUp}
                            />
                            <FormInput
                                label="Password"
                                type="password"
                                name="password"
                                placeholder="********"
                                value={credentials.password}
                                onChange={updateCredentials}
                                className="pb-6"
                                error={errors.password}
                                onKeyUp={keyUp}
                            />
                        </div>
                        <div className="flex flex-wrap items-center justify-center gap-4 mb-4">
                            <Button type="primary" element="button" onClick={handleSubmit} disabled={submitted}>
                                Login
                            </Button>
                            <Button href="/account/forgot-password" type="transparent" element="a">
                                Forgot password?
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="py-8">
                    <div className="flex flex-wrap items-center justify-center gap-4 my-auto mb-4">
                        <Button href="/account/sign-up" type="secondary" element="a">
                            No account yet? Sign up
                        </Button>
                        <Button element="a" type="transparent" href="/">
                            Back to Home
                        </Button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default LoginPage;
